// App.js
import React from 'react';
import './styles/App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import Ola from './components/Ola';
import Experience from './components/Experience';
import Portfolio from './components/Portfolio';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import SocialLinks from './components/SocialLinks';

const App = () => {
    return (
        <div className="App">
            <SocialLinks />
            <Header />
            <Hero />
            <Ola />
            <Experience />
            <Portfolio />
            <ContactForm />
            <Footer />
        </div>
    );
};

export default App;
