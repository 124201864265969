// Portfolio.js
import React from 'react';
import '../styles/Portfolio.css';
import { FaCode, FaDatabase, FaCloud } from 'react-icons/fa'; // Exemplos de ícones

const projects = [
    {
        title: 'Microsserviços Itaú',
        description: 'Implementação do zero de 3 microsserviços.',
        technologies: ['Java', 'Spring Boot', 'AWS'],
        icon: <FaCode className="project-icon" />,
        link: 'https://www.itau.com.br/' // Adicione o link do projeto
    },
    {
        title: 'Assistente Virtual Itaú',
        description: 'Desenvolvimento/manutenção de assistente virtual com IA.',
        technologies: ['Machine Learning', 'JavaScript'],
        icon: <FaDatabase className="project-icon" />,
        link: 'https://api.whatsapp.com/send?1=pt_BR&phone=551140044828&text=Oi,%20Ita%C3%BA.' // Adicione o link do projeto
    },
    {
        title: 'Microsserviços Oi',
        description: 'Manutenção de microsserviços em Java e Node.js.',
        technologies: ['Java', 'Node.js', 'Docker'],
        icon: <FaCloud className="project-icon" />,
        link: 'https://www.oi.com.br/' // Adicione o link do projeto
    },
    {
        title: 'Joice - Oi',
        description: 'Manutenção e testes em assistente virtual.',
        technologies: ['IA', 'Machine Learning'],
        icon: <FaCode className="project-icon" />,
        link: 'https://api.whatsapp.com/send/?phone=558000229806&text=Ol%C3%A1%2C+gostaria+de+mais+informa%C3%A7%C3%B5es.&type=phone_number&app_absent=0' // Adicione o link do projeto
    }
];

const Portfolio = () => {
    return (
        <section className="portfolio" id="portfolio">
            <h2>&lt;trabalho/&gt;</h2>
            <div className="portfolio-items">
                {projects.map((project, index) => (
                    <div className="portfolio-item" key={index}>
                        <div className="portfolio-icon">{project.icon}</div>
                        <div className="portfolio-text">
                            <h3>{project.title}</h3>
                            <p>{project.description}</p>
                            <div className="technologies">
                                {project.technologies.map((tech, techIndex) => (
                                    <span key={techIndex} className="tech-item">{tech}</span>
                                ))}
                            </div>
                            <a href={project.link} target="_blank" rel="noopener noreferrer" className="project-link">
                                Ver Projeto
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Portfolio;
