// SocialLinks.js
import React from 'react';
import '../styles/SocialLinks.css';
import { FaLinkedin, FaGithub } from 'react-icons/fa'; // Importando os ícones do react-icons

const SocialLinks = () => {
    return (
        <div className="social-links">
            <a href="https://www.linkedin.com/in/hugo-barreto-ferreira-665bba1b7/" target="_blank" rel="noopener noreferrer">
                <FaLinkedin className="social-icon" />
            </a>
            <a href="https://github.com/hbferreira1" target="_blank" rel="noopener noreferrer">
                <FaGithub className="social-icon" />
            </a>
        </div>
    );
};

export default SocialLinks;
