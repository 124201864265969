// Experience.js
import React, { useState } from 'react';
import '../styles/Experience.css';

const Experience = () => {
    const [selectedCompany, setSelectedCompany] = useState(0);

    const experiences = [
        {
            company: 'IBM',
            role: 'Application developer',
            duration: '2021 - 2024',
            responsibilities: [
                'Criação de softwares desde a sua fase inicial.',
                'Manutenção/testes de APIs existentes',
                'Serviços de Inteligência artificial e Machicne Learning',
            ]
        }
        // Aqui pode adicionar mais experiências conforme necessário
    ];

    return (
        <section id="experience" className="experience-section">
            <div className="container">
                <h2 className="section-title">&lt;experiência/&gt;</h2>
                <div className="experience-content">
                    <ul className="company-list">
                        {experiences.map((exp, index) => (
                            <li
                                key={index}
                                className={`company-item ${selectedCompany === index ? 'active' : ''}`}
                                onClick={() => setSelectedCompany(index)}
                            >
                                {exp.company}
                            </li>
                        ))}
                    </ul>
                    <div className="experience-details">
                        <h3 className="role">{experiences[selectedCompany].role}</h3>
                        <span className="duration">{experiences[selectedCompany].duration}</span>
                        <ul className="responsibilities">
                            {experiences[selectedCompany].responsibilities.map((task, index) => (
                                <li key={index}>{task}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Experience;
