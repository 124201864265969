import React, { useState } from 'react';
import '../styles/ContactForm.css';
import emailjs from 'emailjs-com';

const ContactForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSending(true);

        // Parâmetros do EmailJS
        const templateParams = {
            name: name,
            email: email,
            message: message,
            reply_to: email // Este campo será usado para o e-mail do usuário
        };

        // Enviar e-mail de confirmação para o usuário
        emailjs.send('gmailMessage', 'template_tccw9v8', templateParams, 'BXHA1NLc05njFZkbY')
            .then((result) => {
                // Enviar e-mail com detalhes da mensagem para o administrador
                return emailjs.send('gmailMessage', 'template_zf73od8', templateParams, 'BXHA1NLc05njFZkbY');
            })
            .then((result) => {
                setSuccessMessage('Mensagem enviada com sucesso!');
                setName('');
                setEmail('');
                setMessage('');
            })
            .catch((error) => {
                console.error('Erro ao enviar e-mail:', error);
                setSuccessMessage('Ocorreu um erro ao enviar a mensagem. Tente novamente.');
            })
            .finally(() => {
                setIsSending(false);
            });
    };

    return (
        <section className="contact-section" id="contact">
            <div className="contact-container">
                <h2 className="section-title">&lt;contato/&gt;</h2>
                <form className="contact-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">Nome</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Seu nome"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Seu email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Mensagem</label>
                        <textarea
                            id="message"
                            name="message"
                            rows="4"
                            placeholder="Sua mensagem"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                        ></textarea>
                    </div>
                    <button type="submit" className="submit-btn" disabled={isSending}>
                        {isSending ? 'Enviando...' : 'Enviar Mensagem'}
                    </button>
                    {successMessage && <p className="success-message">{successMessage}</p>}
                </form>
            </div>
        </section>
    );
};

export default ContactForm;
