// Footer.js
import React from 'react';
import '../styles/Footer.css';
import { FaLinkedin, FaGithub } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <p className="footer-text">
                    © 2024 Hugo - Todos os direitos reservados.
                </p>
            </div>
        </footer>
    );
};

export default Footer;
