// Hero.js
import React from 'react';
import '../styles/Hero.css';

const Hero = () => {
    return (
        <div className="hero">
            <div className="hero-content">
                <h1>
                    <span className="white-text">&lt;Eu sou Hugo,</span><br />
                    <span className="yellow-text">um desenvolvedor de software</span>
                    <span className="white-text">/&gt;</span>
                    <p className='subtext'>
                        Descubra meu trabalho e projetos incríveis.</p>
                </h1>
            </div>
        </div>
    );
};

export default Hero;
