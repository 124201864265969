// Ola.js
import React from 'react';
import '../styles/Ola.css';
import minhaFoto from '../images/eu.jpg'; // Importe a imagem

const skills = [
    {
        topic: 'Java',
        details: [
            'Desenvolvimento de aplicações back-end.',
            'Experiência com frameworks como Spring Boot.'
        ]
    },
    {
        topic: 'JavaScript',
        details: [
            'Desenvolvimento front-end e back-end.',
            'Experiência com React e Node.js.'
        ]
    },
    {
        topic: 'Python',
        details: [
            'Desenvolvimento de scripts e automações.',
            'Experiência com Django e Flask.'
        ]
    },
    // Adicione mais tópicos conforme necessário
];

const Ola = () => {
    return (
        <section className="hi-section" id="hi">
            <div className="container">
                <h2 className="section-title">&lt;olá/&gt;</h2>
                <div className="hi-content">
                    <div className="hi-text">
                        <p>
                            Desenvolvedor de software apaixonado por criar soluções inovadoras e eficientes para problemas complexos. Com experiência em diversas tecnologias, estou sempre buscando novas maneiras de melhorar minhas habilidades e contribuir para projetos emocionantes. Vamos construir algo incrível juntos!
                        </p>
                    </div>
                    <div className="hi-image">
                        <img src={minhaFoto} alt="Foto de Hugo" />
                    </div>
                </div>
                <div className="skills-section">
                    <h6 className="skills-title">Aqui estão algumas das minhas principais <span className="highlight, yellow-text">skills</span>:</h6>
                    <ul className="skills-list">
                        {skills.map(skill => (
                            <li className="skill-item" key={skill.topic}>
                                <div className="skill-topic">{skill.topic}</div>
                                <div className="skill-details">
                                    {skill.details.map((detail, index) => (
                                        <p key={index}>{detail}</p>
                                    ))}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default Ola;
