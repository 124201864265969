// Header.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import '../styles/Header.css';
import { FaBars } from 'react-icons/fa'; // Ícone de menu de barras

const Header = () => {
    const [showNav, setShowNav] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [menuOpen, setMenuOpen] = useState(false); // Estado para abrir/fechar o menu

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > lastScrollY) {
                // Scroll para baixo
                setShowNav(false);
            } else {
                // Scroll para cima
                setShowNav(true);
            }
            setLastScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);

    // Função para rolar até o topo da página
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Para um scroll suave
        });
    };

    // Função para alternar o menu
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header className={showNav ? 'header show' : 'header hide'}>
            <nav className="nav-container">
                <div className="logo-text" onClick={scrollToTop}>
                    <span className="logo-white">hugo.</span>
                    <span className="logo-yellow">barreto()</span>
                </div>
                <div className={`menu-icon ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                    <div className="icon-bar"></div>
                    <div className="icon-bar"></div>
                    <div className="icon-bar"></div>
                </div>
                <ul className={`menu ${menuOpen ? 'open' : ''}`}>
                    <li>
                        <Link
                            activeClass="active"
                            to="hi"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            onClick={toggleMenu} // Fecha o menu ao clicar
                        >
                            olá
                        </Link>
                    </li>
                    <li>
                        <Link
                            activeClass="active"
                            to="experience"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            onClick={toggleMenu} // Fecha o menu ao clicar
                        >
                            experiência
                        </Link>
                    </li>
                    <li>
                        <Link
                            activeClass="active"
                            to="portfolio"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            onClick={toggleMenu} // Fecha o menu ao clicar
                        >
                            trabalho
                        </Link>
                    </li>
                    <li>
                        <Link
                            activeClass="active"
                            to="contact"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            onClick={toggleMenu} // Fecha o menu ao clicar
                        >
                            contato
                        </Link>
                    </li>
                    <li>
                        <a href="/Hugo-Barreto-Ferreira-pt.pdf" target="_blank" className="resume-button" onClick={toggleMenu}>
                            resume
                        </a>
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;
